<template>
  <div id="panel">
    <div class="d-flex" id="wrapper" :class="{ toggled: hideSidebar }">

    <Sidebar></Sidebar>

      <div id="page-content-wrapper">

        <Navbar @toggle-sidebar="hideSidebar = !hideSidebar"></Navbar>

        <div class="container-fluid">

          <router-view></router-view>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"
import Navbar from "../components/Navbar.vue"

export default {
  name: "Panel",
  data: function () {
    return {
      hideSidebar: false,
      perfil: {
        usuario: 'dev',
        admin: false
      }
    }
  },
  components: {
    Sidebar,
    Navbar
  },
  watch: {
    $route() {
      this.hideSidebar = false
    }
  }
}
</script>

<style>
  #panel {
    overflow-x: hidden;
  }

  .not-active {
    text-decoration: none;
    color: black;
  }

  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
  }

  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }

  #sidebar-wrapper .list-group {
    width: 15rem;
  }

  #page-content-wrapper {
    min-width: 100vw;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
    }
  }
</style>
