<template>
  <component :is="layout"></component>
</template>
<script>
  import PublicLayout from './layouts/Public.vue'
  import PanelLayout from './layouts/Panel.vue'

  export default {
    components: {
      PublicLayout,
      PanelLayout
    },
    computed: {
      layout() {
        return this.$store.state.layout
      }
    }
  }
</script>
