<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom justify-content-between">
  <button @click="$emit('toggle-sidebar')" class="btn m-1 btn-success"><i class="fas fa-bars"></i></button>
  <div class="navbar-nav">
    <router-link to="/perfil" class="nav-item nav-link"><i class="fas fa-user"></i> {{ perfil.usuario }}</router-link>
    <a href="#" @click.prevent="logout" class="nav-item nav-link"><i class="fas fa-sign-out-alt"></i> Salir</a>
  </div>
  </nav>
</template>

<script>
  import axios from 'axios'
  import config from '../config'
  import Swal from 'sweetalert2'

  export default {
    name: "Navbar",
    computed: {
      perfil() {
        return this.$store.state.perfil
      }
    },
    methods: {
      async logout() {
        try {
          await axios.get(config.apiUrl + '/usuarios/logout')

          this.$router.push({ name: 'Login' })
        } catch (e) {
          Swal.fire({
            title: 'Error',
            text: e.response.data,
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true
          })
        }
      }
    }
  }
</script>
