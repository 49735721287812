import axios from 'axios';
import config from '../config';
import store from '../store';
import Swal from 'sweetalert2';

export default {
  async public(to, from, next) {
    try {
      await axios.get(config.apiUrl + '/usuarios/perfil');

      next(false);
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          return next();
        }
      }

      Swal.fire({
        title: 'Error',
        text: 'El servidor no responde, inténtalo más tarde',
        icon: 'error',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      next(false);
    }
  },
  async user(to, from, next) {
    try {
      const res = await axios.get(config.apiUrl + '/usuarios/perfil');

      store.commit('setPerfil', res.data);

      next();
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          return next('/login');
        }
      }

      Swal.fire({
        title: 'Error',
        text: 'El servidor no responde, inténtalo más tarde',
        icon: 'error',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      next(false);
    }
  },
  async admin(to, from, next) {
    try {
      const res = await axios.get(config.apiUrl + '/usuarios/perfil');

      if (!res.data.admin) {
        Swal.fire({
          title: 'Error',
          text:
            'Tienes que iniciar sesión como administrador para acceder a este enlace',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });

        return next(false);
      }

      store.commit('setPerfil', res.data);

      next();
    } catch (e) {
      if (e.response) {
        if (e.response.status === 401) {
          Swal.fire({
            title: 'Error',
            text:
              'Tienes que iniciar sesión como administrador para acceder a este enlace',
            icon: 'error',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });

          return next(false);
        }
      }

      Swal.fire({
        title: 'Error',
        text: 'El servidor no responde, inténtalo más tarde',
        icon: 'error',
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      next(false);
    }
  },
};
