import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from './auth'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    beforeEnter: auth.public
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import('../views/Registro.vue'),
    beforeEnter: auth.public
  },
  {
    path: '/recuperarpasswd',
    name: 'RecuperarPasswd',
    component: () => import('../views/RecuperarPasswd.vue'),
    beforeEnter: auth.public
  },
  {
    path: '/nuevapasswd',
    name: 'NuevaPasswd',
    component: () => import('../views/NuevaPasswd.vue'),
    beforeEnter: auth.public
  },
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/bienvenido',
    name: 'Bienvenido',
    component: () => import('../views/Bienvenido.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: () => import('../views/Perfil.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/solicitudes',
    name: 'Solicitudes',
    component: () => import('../views/Solicitudes.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/pagos',
    name: 'Pagos',
    component: () => import('../views/Pagos.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/appemby',
    name: 'Appemby',
    component: () => import('../views/Appemby.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('../views/Contacto.vue'),
    beforeEnter: auth.user
  },
  {
    path: '/usuarios',
    component: () => import('../views/Usuarios.vue'),
    beforeEnter: auth.admin,
    children: [
      {
        path: '',
        name: 'Usuarios',
        component: () => import('../components/usuarios/ListaUsuarios.vue')
      },
      {
        path: ':embyId',
        name: 'Usuario',
        component: () => import('../components/usuarios/Usuario.vue')
      }
    ]
  },
  {
    path: '/ingresos',
    name: 'Ingresos',
    component: () => import('../views/Ingresos.vue'),
    beforeEnter: auth.admin
  },
  {
    path: '/gastos',
    name: 'Gastos',
    component: () => import('../views/Gastos.vue'),
    beforeEnter: auth.admin
  },
  {
    path: '/estadisticas',
    name: 'Estadisticas',
    component: () => import('../views/Estadisticas.vue'),
    beforeEnter: auth.admin
  },
  {
    path: '/descargas',
    component: () => import('../views/Descargas.vue'),
    beforeEnter: auth.admin,
    children: [
      {
        path: '',
        name: 'Descargas',
        component: () => import('../components/descargas/DescargasNormal.vue'),
      },
      {
        path: 'series',
        name: 'DescargasSeries',
        component: () => import('../components/descargas/DescargasSeries.vue'),
      }
    ]
  },
  {
    path: '/herramientas',
    name: 'Herramientas',
    component: () => import('../views/Herramientas.vue'),
    beforeEnter: auth.admin
  },
  {
    path: '/conversor',
    name: 'Conversor',
    component: () => import('../views/Conversor.vue'),
    beforeEnter: auth.admin
  },
  {
    path :'*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
