import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from './config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    perfil: {
      admin: null,
      usuario: null
    },
    layout: 'PanelLayout'
  },
  mutations: {
    setPublicLayout(state) {
      state.layout = 'PublicLayout'
    },
    setPanelLayout(state) {
      state.layout = 'PanelLayout'
    },
    setPerfil(state, perfil) {
      state.perfil = perfil
    },
    async reloadPerfil(state) {
      const res = await axios.get(config.apiUrl + '/usuarios/perfil')

      state.perfil = res.data
    }
  }
})
