<template>
  <div class="text-light bg-dark border-right" id="sidebar-wrapper">
  <div class="sidebar-heading font-weight-bold text-uppercase"><img src="../assets/images/bemag-full-white-500.png" class="img-fluid" style="max-width: 170px"></div>
  <div class="list-group list-group-flush">
    <router-link to="/" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-home"></i> Inicio</router-link>
    <router-link to="/solicitudes" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-film"></i> Solicitudes</router-link>
    <router-link to="/pagos" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-money-check-alt"></i> Pagos</router-link>
    <router-link to="/appemby" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-tablet-alt"></i> App</router-link>
    <router-link to="/contacto" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-headset"></i> Contacto</router-link>

    <div v-show="perfil.admin">
      <div class="sidebar-heading font-weight-bold text-uppercase">Admin</div>
      <router-link to="/usuarios" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-users"></i> Usuarios</router-link>
      <router-link to="/ingresos" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-wallet"></i> Ingresos</router-link>
      <router-link to="/gastos" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-cart-arrow-down"></i> Gastos</router-link>
      <router-link to="/estadisticas" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-chart-line"></i> Estadísticas</router-link>
      <router-link to="/herramientas" class="list-group-item list-group-item-action text-light bg-dark"><i class="fas fa-tools"></i> Herramientas</router-link>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "Sidebar",
    computed: {
      perfil() {
        return this.$store.state.perfil
      }
    },
  }
</script>
